import { CodesCxpress } from "../core/enums/codes-cxpress"

export function verifyCode(code: number, t: (key: string) => string) {
    switch (code) {
        //0 - 99: Erros de conexão com o banco de dados
        case 0:
            return t(CodesCxpress.DatabaseConnectionInfo)
        case 1:
            return t(CodesCxpress.DatabaseConnectionError)
        case 2:
            return t(CodesCxpress.DatabaseQueryError)
        case 3:
            return t(CodesCxpress.DatabaseInsertError)
        case 4:
            return t(CodesCxpress.DatabaseUpdateError)
        case 5:
            return t(CodesCxpress.DatabaseDeleteError)
        case 6:
            return t(CodesCxpress.DatabaseTransactionError)
        case 7:
            return t(CodesCxpress.DatabaseCommitError)
        case 8:
            return t(CodesCxpress.DatabaseRollbackError)
    
        //100 - 199: Erros de conexão com o servidor
        case 100:
            return t(CodesCxpress.ServerConnectionInfo)
        case 101:
            return t(CodesCxpress.ServerConnectionError)
        case 102:
            return t(CodesCxpress.ServerRequestError)
        case 103:
            return t(CodesCxpress.ServerResponseError)
    
        //200 - 299: Erros de conexão com o WhatsApp
        case 200:
            return t(CodesCxpress.WhatsAppConnectionInfo)
        case 201:
            return t(CodesCxpress.WhatsAppConnectionError)
        case 202:
            return t(CodesCxpress.WhatsAppRequestError)
        case 203:
            return t(CodesCxpress.WhatsAppResponseError)
        case 204:
            return t(CodesCxpress.WhatsAppRequestBotError)
        case 205:
            return t(CodesCxpress.WhatsAppResponseBotError)
        case 206:
            return t(CodesCxpress.WhatsAppKeysSaveError)
        case 207:
            return t(CodesCxpress.WhatsAppWebHookError)
        case 208:
            return t(CodesCxpress.WhatsAppWebHookPositusError)
        case 209:
            return t(CodesCxpress.WhatsAppNumbersGetError)
        case 210:
            return t(CodesCxpress.WhatsAppTwilioCallBack)
        case 211:
            return t(CodesCxpress.WhatsAppNotFoundBrokenIdError)
        case 212:
            return t(CodesCxpress.WhatsAppNumberInUseError)
        case 213:
            return t(CodesCxpress.WhatsAppKeysUpdateError)
        
        //300 - 399: Erros de conexão com o Facebook
        case 300:
            return t(CodesCxpress.FacebookConnectionInfo)
        case 301:
            return t(CodesCxpress.FacebookConnectionError)
        case 302:
            return t(CodesCxpress.FacebookRequestError)
        case 303:
            return t(CodesCxpress.FacebookResponseError)
        case 304:
            return t(CodesCxpress.FacebookRequestBotError)
        case 305:
            return t(CodesCxpress.FacebookResponseBotError)
        case 306:
            return t(CodesCxpress.FacebookTokenValidationError)
        case 307:
            return t(CodesCxpress.FacebookWebHookError)
        case 308:
            return t(CodesCxpress.FacebookListPagesError)
        case 309:
            return t(CodesCxpress.FacebookNoKeyFoundError)
        case 310:
            return t(CodesCxpress.FacebookDuplicateKeysError)
        case 311:
            return t(CodesCxpress.FacebookKeysSaveError)
        case 312:
            return t(CodesCxpress.FacebookMissingRequiredFieldError)
        case 313:
            return t(CodesCxpress.FacebookDuplicatePagesError)
        case 314:
            return t(CodesCxpress.FacebookCompanyNotFoundError)
        case 315:
            return t(CodesCxpress.FacebookSaveKeysError)
    
        //400 - 499: Erros de conexão com o Instagram
        case 400:
            return t(CodesCxpress.InstagramConnectionInfo)
        case 401:
            return t(CodesCxpress.InstagramConnectionError)
        case 402:
            return t(CodesCxpress.InstagramRequestError)
        case 403:
            return t(CodesCxpress.InstagramResponseError)
        case 404:
            return t(CodesCxpress.InstagramRequestBotError)
        case 405:
            return t(CodesCxpress.InstagramResponseBotError)
        case 406:
            return t(CodesCxpress.InstagramWebHookError)
        case 407:
            return t(CodesCxpress.InstagramListPagesError)
        case 408:
            return t(CodesCxpress.InstagramDuplicatePagesError)
        case 409:
            return t(CodesCxpress.InstagramCompanyNotFoundError)
        case 410:
            return t(CodesCxpress.InstagramNoKeyFoundError)
        case 411:
            return t(CodesCxpress.InstagramMissingRequiredFieldError)
        case 412:
            return t(CodesCxpress.InstagramInvalidTokenError)
            
        //500 - 599: Erros de conexão com o Webchat
        case 500:
            return t(CodesCxpress.WebchatConnectionInfo)
        case 501:
            return t(CodesCxpress.WebchatConnectionError)
        case 502:
            return t(CodesCxpress.WebchatRequestError)
        case 503:
            return t(CodesCxpress.WebchatResponseError)
        case 504:
            return t(CodesCxpress.WebchatRequestBotError)
        case 505:
            return t(CodesCxpress.WebchatResponseBotError)
        case 506:
            return t(CodesCxpress.WebchatCompanyNotFoundError)
        case 507:
            return t(CodesCxpress.WebchatUpdateKeysError)
        case 508:
            return t(CodesCxpress.WebchatLoadKeysError)
    
        //600 - 699: Erros de email
        case 600:
            return t(CodesCxpress.EmailConnectionInfo)
        case 601:
            return t(CodesCxpress.EmailConnectionError)
        case 602:
            return t(CodesCxpress.EmailRequestError)
        case 603:
            return t(CodesCxpress.EmailResponseError)
        case 604:
            return t(CodesCxpress.EmailSendGridError)
    
        //700 - 799: Erro de ticket
        case 700:
            return t(CodesCxpress.TicketConnectionInfo)
        case 701:
            return t(CodesCxpress.TicketError)
        case 702:
            return t(CodesCxpress.TicketCreateError)
        case 703:
            return t(CodesCxpress.TicketUpdateError)
        case 704:
            return t(CodesCxpress.TicketDeleteError)
        case 705:
            return t(CodesCxpress.TicketSearchError)
        case 706:
            return t(CodesCxpress.TicketSearchByIdError)
        case 707:
            return t(CodesCxpress.TicketSearchByChannelError)
        case 708:
            return t(CodesCxpress.TicketSearchByStatusError)
        case 709:
            return t(CodesCxpress.TicketSearchByCompanyError)
        case 710:
            return t(CodesCxpress.TicketSearchByEmployeeError)
        case 711:
            return t(CodesCxpress.TicketSearchByTagError)
        case 712:
            return t(CodesCxpress.TicketSearchByLevelError)
        case 713:
            return t(CodesCxpress.TicketSearchByPriorityError)
        case 714:
            return t(CodesCxpress.TicketUpdateLevelError)
        case 715:
            return t(CodesCxpress.TicketUpdateStatusError)
        case 716:
            return t(CodesCxpress.TicketN1AlreadyInUseError)
        case 717:
            return t(CodesCxpress.TicketNotAllowedFacebookOrInstagramError)
        case 718:
            return t(CodesCxpress.TicketNotFoundError)
        case 719:
            return t(CodesCxpress.TicketTagsNotFoundError)
        case 720:
            return t(CodesCxpress.TicketBotError)
        case 726:
            return t(CodesCxpress.DeletionNotPermitted)
    
        //800 - 899: Erros de Agent
        case 800:
            return t(CodesCxpress.AgentConnectionInfo)
        case 801:
            return t(CodesCxpress.AgentConnectionError)
        case 802:
            return t(CodesCxpress.AgentCreateError)
        case 803:
            return t(CodesCxpress.AgentUpdateError)
        case 804:
            return t(CodesCxpress.AgentDeleteError)
        case 805:
            return t(CodesCxpress.AgentDeleteByIdError)
        case 806:
            return t(CodesCxpress.AgentSearchError)
        case 807:
            return t(CodesCxpress.AgentCreateLimitError)
        case 808:
            return t(CodesCxpress.AgentUpdateEmailInUseError)
        case 809:
            return t(CodesCxpress.AgentUpdateNotFoundError)
        case 810:
            return t(CodesCxpress.AgentAssociateSectorError)
        case 811:
            return t(CodesCxpress.AgentCreateSectorNotInTheSameCompanyError)
        case 812:
            return t(CodesCxpress.AgentDeleteAssociateAgentAndSectorError)
        case 813:
            return t(CodesCxpress.AgentListSectorError)
        case 814:
            return t(CodesCxpress.AgentCreateAlreadyExists)
    
        //900 - 999: Erros de Channel
        case 900:
            return t(CodesCxpress.ChannelConnectionInfo)
        case 901:
            return t(CodesCxpress.ChannelConnectionError)
        case 902:
            return t(CodesCxpress.ChannelCreateError)
        case 903:
            return t(CodesCxpress.ChannelUpdateError)
        case 904:
            return t(CodesCxpress.ChannelDeleteError)
        case 905:
            return t(CodesCxpress.ChannelSendEmailError)
        
        //1000 - 1099: Erros de Authentication
        case 1000:
            return t(CodesCxpress.AuthenticationConnectionInfo)
        case 1001:
            return t(CodesCxpress.AuthenticationConnectionError)
        case 1002:
            return t(CodesCxpress.AuthenticationCreateError)
        case 1003:
            return t(CodesCxpress.AuthenticationUpdateError)
        case 1004:
            return t(CodesCxpress.AuthenticationDeleteError)
        case 1005:
            return t(CodesCxpress.AuthenticationCreateCnpjError)
        case 1006:
            return t(CodesCxpress.AuthenticationLoginError)
        case 1007:
            return t(CodesCxpress.AuthenticationUserNotFoundError)
        case 1008:
            return t(CodesCxpress.AuthenticationResetPasswordError)
        case 1009:
            return t(CodesCxpress.AuthenticationEmailNotConfirmedError)
        case 1019:
            return t(CodesCxpress.AuthenticationCreateCnpjInvalid)
    
       //1100 - 1199: Erros de Chat
        case 1100:
            return t(CodesCxpress.ChatConnectionInfo)
        case 1101:
            return t(CodesCxpress.ChatConnectionError)
        case 1102:
            return t(CodesCxpress.ChatFileSizeNotAllowed)
        case 1103:
            return t(CodesCxpress.ChatFileDoesNotExist)
        case 1108:
            return t(CodesCxpress.ChatFileErrorUpload)
        case 1110:
            return t(CodesCxpress.ChatFileExceed)
        case 1111:
            return t(CodesCxpress.ChatFileImageExceed)
        case 1113:
            return t(CodesCxpress.ChatFileNotAccepted)
        case 1114:
            return t(CodesCxpress.ChatFileExceedNew)
        
       //1200 - 1299: Erros de Company
        case 1200:
            return t(CodesCxpress.CompanyConnectionInfo)
        case 1201:
            return t(CodesCxpress.CompanyConnectionError)
        case 1202:
            return t(CodesCxpress.CompanyInsertError)
        case 1203:
            return t(CodesCxpress.CompanySubdomainNotInformedError)
        case 1204:
            return t(CodesCxpress.CompanySubdomainNotRegisteredError)
        case 1205:
            return t(CodesCxpress.CompanyDeleteError)
        case 1206:
            return t(CodesCxpress.CompanyUserNotFoundError)
        case 1207:
            return t(CodesCxpress.CompanyNotFoundError)
        case 1208:
            return t(CodesCxpress.CompanyAuthorizationError)
        case 1209:
            return t(CodesCxpress.CompanyRegisterEmailError)
    
       //1300 - 1399: Erros de Comunicate External
        case 1300:
            return t(CodesCxpress.ComunicateExternalConnectionInfo)
        case 1301:
            return t(CodesCxpress.ComunicateExternalConnectionError)
        case 1302:
            return t(CodesCxpress.ComunicateExternalAuthenticateDomainError)
    
       //1400 - 1499: Erros de Consumer
        case 1400:
            return t(CodesCxpress.ConsumerConnectionInfo)
        case 1401:
            return t(CodesCxpress.ConsumerConnectionError)
        case 1402:
            return t(CodesCxpress.ConsumerCreateError)
        case 1403:
            return t(CodesCxpress.ConsumerUpdateError)
        case 1404:
            return t(CodesCxpress.ConsumerDeleteError)
        case 1405:
            return t(CodesCxpress.ConsumerUpdadeWithExistingInformationError)
        case 1406:
            return t(CodesCxpress.ConsumerNotFoundError)
        case 1407:
            return t(CodesCxpress.ConsumerCreateAlreadyExists)
        case 1412:
            return t(CodesCxpress.ConsumerAgentAssociate)
        
       //1500 - 1599: Erros de Contract
        case 1500:
            return t(CodesCxpress.ContractConnectionInfo)
        case 1501:
            return t(CodesCxpress.ContractConnectionError)
        case 1502:
            return t(CodesCxpress.ContractCreateError)
        case 1503:
            return t(CodesCxpress.ContractUpdateError)
        case 1504:
            return t(CodesCxpress.ContractDeleteError)
        case 1505:
            return t(CodesCxpress.ContractSearchByCompanyIdError)
        case 1506:
            return t(CodesCxpress.ContractSearchByContractIdError)
        case 1507:
            return t(CodesCxpress.ContractDeleteByCompanyIdError)
        
       //1600 - 1699: Erros de bot-cxpress
        case 1600:
            return t(CodesCxpress.BotConnectionInfo)
        case 1601:
            return t(CodesCxpress.BotConnectionError)
        case 1602:
            return t(CodesCxpress.BotCreateError)
        case 1603:
            return t(CodesCxpress.BotUpdateError)
        case 1604:
            return t(CodesCxpress.BotDeleteError)
        case 1605:
            return t(CodesCxpress.BotSaveWebChatKeysError)
        case 1606:
            return t(CodesCxpress.BotNotFoundDataToRegistWebChatKeysError)
        
       //1700 - 1799: Códigos de User
        case 1700:
            return t(CodesCxpress.UserConnectionInfo)
        case 1701:
            return t(CodesCxpress.UserError)
    
       //1800 - 1899: Códigos de Vozxpress
        case 1800:
            return t(CodesCxpress.VozxpressConnectionInfo)
        case 1801:
            return t(CodesCxpress.VozxpressError)
        case 1802:
            return t(CodesCxpress.VozxpressAPIError)
        case 1803:
            return t(CodesCxpress.VozxpressAccountNotFoundError)
        case 1804:
            return t(CodesCxpress.VozxpressSendEmailError)
        case 1805:
            return t(CodesCxpress.VozxpressCompanyNotFoundError)
        case 1806:
            return t(CodesCxpress.VozxpressCreateTicketError)
        case 1807:
            return t(CodesCxpress.VozxpressInvalidTokenError)
        case 1808:
            return t(CodesCxpress.VozxpressCreateAudioCallError)
        
       //1900 - 1999: Erros de message-sending
        case 1900:
            return t(CodesCxpress.MessageSendingConnectionInfo)
        case 1901:
            return t(CodesCxpress.MessageSendingConnectionError)
        case 1902:
            return t(CodesCxpress.MessageSendingCreateError)
        case 1903:
            return t(CodesCxpress.MessageSendingUpdateError)
        case 1904:
            return t(CodesCxpress.MessageSendingDeleteError)
        case 1905:
            return t(CodesCxpress.MessageSendingSearchError)
        case 1906:
            return t(CodesCxpress.MessageSendingValidateTypeError)
        case 1907:
            return t(CodesCxpress.MessageSendingInvalidDateError)
        
       //2000 - 2099: Erros de message-sending-type
        case 2000:
            return t(CodesCxpress.MessageSendingTypeConnectionInfo)
        case 2001:
            return t(CodesCxpress.MessageSendingTypeConnectionError)
        case 2002:
            return t(CodesCxpress.MessageSendingTypeCreateError)
        case 2003:
            return t(CodesCxpress.MessageSendingTypeUpdateError)
        case 2004:
            return t(CodesCxpress.MessageSendingTypeDeleteError)
        case 2005:
            return t(CodesCxpress.MessageSendingTypeCreateWithNameInUseError)
    
       //2100 - 2199: Erros de metrics
        case 2100:
            return t(CodesCxpress.MetricsTypeConnectionInfo)
        case 2101:
            return t(CodesCxpress.MetricsTypeConnectionError)
        case 2102:
            return t(CodesCxpress.MetricsSearchError)
    
       //2200 - 2299: Erros de TemplateMessage
        case 2200:
            return t(CodesCxpress.TemplateMessageConnectionInfo)
        case 2201:
            return t(CodesCxpress.TemplateMessageError)
        case 2202:
            return t(CodesCxpress.TemplateMessageCreateError)
        case 2203:
            return t(CodesCxpress.TemplateMessageDeleteError)
        case 2204:
            return t(CodesCxpress.TemplateMessageUpdateError)
        case 2205:
            return t(CodesCxpress.TemplateMessagePhoneNumberNotFoundError)
        case 2206:
            return t(CodesCxpress.TemplateMessageNotFoundError)
    
       //2300 - 2399: Erros de Tag
        case 2300:
            return t(CodesCxpress.TagConnectionInfo)
        case 2301:
            return t(CodesCxpress.TagError)
        case 2302:
            return t(CodesCxpress.TagCreateError)
        case 2303:
            return t(CodesCxpress.TagDeleteError)
        case 2304:
            return t(CodesCxpress.TagUpdateError)
        case 2305:
            return t(CodesCxpress.TagNoTicketsFoundError)
        case 2306:
            return t(CodesCxpress.TagNotFoundError)
        case 2307:
            return t(CodesCxpress.TagUpdadeWithExistingTagError)
    
       //2400 - 2499: BotCxpress
        case 2400:
            return t(CodesCxpress.errorToSaveWebChatKeys)
        case 2401:
            return t(CodesCxpress.notFoundDataToRegistWebChatKeys)
    
       //2500 - 2599: Erros de TicketAlert
        case 2500:
            return t(CodesCxpress.TicketAlertConnectionInfo)
        case 2501:
            return t(CodesCxpress.TicketAlertError)
        case 2502:
            return t(CodesCxpress.TicketAlertCreateError)
    
       //2600 - 2699: Erros de Panel
        case 2600:
            return t(CodesCxpress.PanelConnectionInfo)
        case 2601:
            return t(CodesCxpress.PanelError)
        case 2602:
            return t(CodesCxpress.PanelUpdateOwnerError)
        case 2603:
            return t(CodesCxpress.PanelInvalidTokenError)
        case 2604:
            return t(CodesCxpress.PanelCompanyNotFoundError)
    
       //2700 - 2799: Erros de Sector
        case 2070:
            return t(CodesCxpress.SectorConnectionInfo)
        case 2701:
            return t(CodesCxpress.SectorError)
        case 2702:
            return t(CodesCxpress.SectorCreateError)
        case 2703:
            return t(CodesCxpress.SectorUpdateError)
        case 2704:
            return t(CodesCxpress.SectorDeleteError)
        case 2705:
            return t(CodesCxpress.SectorNotFoundError)
        case 2706:
            return t(CodesCxpress.SectorEmailRegistered)
        case 2707:
            return t(CodesCxpress.SectorListEmailError)
        case 2708:
            return t(CodesCxpress.SectorEmailRegistered)
        case 2714:
            return t(CodesCxpress.SectorAlreadyWithThisKey);
       //2800 - 2899: Erros de SectorKeyword
        case 2800:
            return t(CodesCxpress.SectorKeywordConnectionInfo)
        case 2801:
            return t(CodesCxpress.SectorKeywordError)
        case 2802:
            return t(CodesCxpress.SectorKeywordCreateError)
        case 2803:
            return t(CodesCxpress.SectorKeywordUpdateOwnerError)
        case 2804:
            return t(CodesCxpress.SectorKeywordInvalidTokenError)
        case 2805:
            return t(CodesCxpress.SectorKeywordCompanyNotFoundError)
    
       //2900 - 2999 Erros Log-Company
        case 2900:
            return t(CodesCxpress.LogCompanyConnectionInfo)
        case 2901:
            return t(CodesCxpress.LogCompanyConnectionError)
        case 2902:
            return t(CodesCxpress.LogCompanyNoHistoryFoundError)
    
       //3000 - 3099: TrelloService
        case 3000:
            return t(CodesCxpress.notFoundTrelloKeys)
        
        default:
            return t(CodesCxpress.defaultError)
    }
}